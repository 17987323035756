<template>
  <div>
    <div
      class="absolute inset-0 flex items-center justify-center bg-gray-100 object-cover object-center"
    ></div>
    <div class="absolute inset-0 flex items-center justify-center">
      <div
        class="-mt-12 p-10 bg-white rounded border-t-4 border-indigo-400 shadow-lg"
      >
        <h3 class="font-semibold text-xl text-gray-600 text-center pb-4">
          永信办公系统
        </h3>
        <input
          v-model="name"
          class="block w-64 px-2 py-2 outline-none border-b border-gray-400 placeholder-gray-400 hover:border-indigo-400"
          placeholder="姓名"
          type="text"
        />
        <input
          v-model="password"
          @keyup.enter="onSubmit"
          class="block w-64 px-2 py-2 outline-none border-b border-gray-400 placeholder-gray-400 hover:border-indigo-400 mt-2"
          placeholder="密码"
          type="password"
        />
        <div
          @click="onSubmit"
          class="w-full py-2 mt-6 bg-white text-center text-gray-600 font-semibold tracking-widest cursor-pointer outline-none rounded-lg shadow hover:text-indigo-500"
        >
          登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";

export default {
  name: "Login",
  setup() {
    const name = ref("");
    const password = ref("");
    const store = useStore();
    const router = useRouter();
    const onSubmit = () => {
      if (!(name.value && password.value)) {
        // this.$notify.warning({ title: "注意", message: "请输入账号和密码" });
        ElNotification.warning({ title: "注意", message: "请输入账号和密码" });
        return;
      }
      store
        .dispatch("user/login", { name: name.value, password: password.value })
        .then(() => {
          // this.$notify.success({ title: "成功", message: "登录成功" });
          // this.$router.push("/");
          ElNotification.success({ title: "成功", message: "登录成功" });
          store.dispatch("config/all");
          store.dispatch("getSystemConfig");
          router.push("/");
        })
        .catch(() => {
          // this.$notify.error({ title: "失败", message: "账号或密码错误" });
          ElNotification.error({ title: "失败", message: "账号或密码错误" });
        });
    };
    return { name, password, onSubmit };
  },
};
</script>
